import React from 'react';
import styled from 'styled-components';
import checkmarkCircle from '../../images/checkmark-circle-blue.png';
import withThankyouWrapper from '../../components/ThankyouPages/withThankyouWrapper';

const ThankyouWrapper = styled.div`
  margin: 0;
`;

const Banner = styled.div`
  width: 100%;
  // max-width: 1170px;
  margin: 0 auto;
  color: #fff;
  background: url('https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eaagva-banner.jpg')
    94% 50% / cover no-repeat;
`;

const BannerContent = styled.div`
  width: 100%;
  max-width: 700px;
  padding: 50px;
  margin: 0 0 0 30px;
  @media (max-width: 801px) {
    width: initial;
    padding: 25px;
  }
  h1 {
    text-align: center;
    font-size: 42px;
    @media (max-width: 801px) {
      font-size: 30px;
    }
  }
  h2 {
    font-size: 30px;
    @media (max-width: 801px) {
      font-size: 20px;
      text-align: center;
    }
  }
  p {
    font-size: 14px;
  }
`;

const MainContent = styled.div`
  width: 100;
  max-width: 1170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin: 30px auto;
  @media (max-width: 801px) {
    flex-direction: column;
    align-items: center;
  }
`;

const Card = styled.div`
  // border: 1px solid #000;
  width: 100%;
  max-width: 350px;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  align-items: center;
  justify-content: space-between;
  box-shadow: 2px 10px 18px #888888;
  @media (max-width: 801px) {
    margin-bottom: 10px;
  }
`;

const CardImageContainer = styled.div`
  width: 100%;
`;

const CardText = styled.div`
  font-size: 16px;
  text-align: center;
  padding: 0 10px;
  width: 100%;
  min-height: 113px;
`;

const CardButton = styled.div`
  border: solid 3px #3c4e80;
  text-align: center;
  padding: 10px 30px;
  width: 75%;
  margin: 0 auto;
  color: #3c4e80;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 10px;
`;

const EaagvaThankyou = () => (
  <ThankyouWrapper>
    <Banner>
      <BannerContent>
        <h1>CONGRATULATIONS!</h1>
        <h2>YOU HAVE BEEN MATCHED WITH AAG VA</h2>
        <p>
          At American Advisors Group, we strive to deliver the most considerate
          solutions and personalized service in an effort to give our customers
          the best financial outcome for a better life. AAG interacts with tens
          of thousands of Americans every month many who are active duty or
          veterans of the Vietnam War or World War II. In 2018, AAG continued
          its efforts to support veterans and those actively serving in the
          United States military by adding government-backed Veterans Affairs
          (VA) loans to its existing product suite.
        </p>
      </BannerContent>
    </Banner>
    <MainContent>
      <Card>
        <CardImageContainer>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eaagvaLogo1.jpg"
            alt="aag-logo"
          />
        </CardImageContainer>
        <CardText>
          <p>
            VA loans help meet the housing needs of eligible veterans who have
            served or are currently serving on active duty. These loans are
            guaranteed by the federal government through the Department of
            Veteran Affairs.
          </p>
        </CardText>
        <CardButton>Caring</CardButton>
      </Card>
      <Card>
        <CardImageContainer>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eaagvaLogo2.jpg"
            alt="aag-logo"
          />
        </CardImageContainer>
        <CardText>
          <p>
            Spouses of military embers who dies while on active duty or as a
            result of a service-connected disability may also be eligible
          </p>
        </CardText>
        <CardButton>Ethical</CardButton>
      </Card>
      <Card>
        <CardImageContainer>
          <img
            src="https://s3.amazonaws.com/sc-dba.com/lender-specific-ty/images/eaagvaLogo3.jpg"
            alt="aag-logo"
          />
        </CardImageContainer>
        <CardText>
          <p>
            Borrower(s) can be active duty, or former military, reserves and
            guards of the Army, Navy, Air Force, Marines, and National Guard
            that meet the VA minimum service requirements
          </p>
        </CardText>
        <CardButton>Driven</CardButton>
      </Card>
    </MainContent>
  </ThankyouWrapper>
);

export default withThankyouWrapper(EaagvaThankyou);
